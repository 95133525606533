<template>
  <div class="party">
    <!-- :style='ruleForm.formList.length>1?"overflow-y:scroll;":""' -->
    <el-form 
    :model="ruleForm" 
    ref="partyForm">
      <div class="title">
        <div class="text">{{title}}</div>
        <div @click='add' class="el-icon-circle-plus-outline"></div>
      </div>
      <div class="partyCon" :style="title==='我方当事人'?'':'padding-bottom:10px'"
      v-for='(formItem,idx) in ruleForm.formList'
      :key='formItem.id'>
        <div class="partyUl">
          <div class="partyLi">
            <div class="itemTop">
              <div class="typeSelect">
                <el-form-item style='margin-bottom:0' 
                :prop="'formList.'+idx+'.type'"
                :rules="{required:true,message:'请选择当事人类型', trigger:['blur','change']}">
                  <el-select v-model="formItem.type" size='small' style='width:100px;'>
                    <el-option
                      v-for="item in typeData"
                      :key='item.key'
                      :label="item.value"
                      :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="typeSelect">
                <el-form-item style='margin-bottom:0'
                :prop="'formList.'+idx+'.identify'"
                :rules="{required:true,message:'请选择当事人身份', trigger:['blur','change']}">
                  <el-select v-model="formItem.identify" size='small' style='width:360px;'>
                    <el-option
                      v-for="item in identifyData"
                      :key="item.key"
                      :label="item.value"
                      :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="el-icon-delete" v-if='idx' @click='deletItem(idx)'></div>
            </div>
            <div class="itemName">
              <el-form-item 
              :prop="'formList.'+idx+'.nameinput'"
              :rules="{required: true, message: '请输入当事人名称', trigger: ['blur','change']}"
              >
                <el-autocomplete :trigger-on-focus='false'  size='small' style='width:464px;' 
                  v-model="formItem.nameinput"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="当事人名称"
                ></el-autocomplete>
              </el-form-item>
            </div>
            <div class="itembottom" v-if='listArr&&listArr.length===0'
            :style="title==='我方当事人'?'':'justify-content:flex-end'">
              <el-form-item style='margin-bottom:0' v-if='title==="我方当事人"'>
                <el-checkbox v-model="formItem.isCreatedCustom">同步新建客户</el-checkbox>
              </el-form-item>
              <div class="status none" v-show='formItem.isConflict===1'>
                <div class='icon el-icon-circle-check'></div>
                <div>没有利益冲突</div>
              </div>
              <div class="status exist"
              @click='conflict'
              v-show='formItem.isConflict===0'>
                <div class='icon el-icon-warning-outline'></div>
                <div>查看利益冲突</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script>
import { defineComponent,ref,reactive,onMounted } from 'vue';
import {useStore} from 'vuex';
import {customerNameSelect,conflictOfInterestCheck} from '@/api';

const getType = (store)=>{
  let typeData = ref([]);
  let identifyData = ref([]);
  // 主体类型
  const getTypeData = async ()=>{
    return await store.dispatch('getDataDictionary',10005);
  };
  // 当事人身份
  const getIdentifyData = async ()=>{
    return await store.dispatch('getDataDictionary',10007);
  };
  onMounted(async ()=>{
    typeData.value = await getTypeData();
    identifyData.value = await getIdentifyData();
  });
  return {
    typeData,
    identifyData
  }
};

export default defineComponent({
  name:"party",
  setup() {
    const store = useStore();
    const {typeData,identifyData} = getType(store);
    return {
      typeData,identifyData,
    }
  },
  props:{
    title:{
      type:String,
      default:'我方当事人'
    },
    listArr:{
      type:Array,
      default:function(){
        return []
      }
    }
  },
  created(){
    if(this.listArr&&this.listArr.length){
      this.ruleForm.formList.splice(0,1);
      this.ruleForm.formList = this.listArr.map(item=>{
        item.id=item.selfKey;
        item.nameinput=item.holderName;
        item.identify=item.partyTypeName;
        item.type=item.holderTypeName;
        // bug
        item.isCreatedCustom=false;
        item.isConflict=false;
        return item
      })
    }
    
  },
  watch:{
    'ruleForm.formList':{
      handler(newV,oldV){
        this.blurCheck(newV)
      },
      deep:true,
    }
  },
  data(){
    return {
      ruleForm: {
        formList:[
          {
            id:1,
            type:'',
            identify:'',
            nameinput:'',
            isCreatedCustom:false,
            isConflict:''
          }
        ]
      },
      serchData:[],
    }
  },
  methods:{
    // 校验
    checkIsRequire(cb){
      this.$refs['partyForm'].validate(val=>{
        cb(val)
      })
    },
    // 重置
    resetFields(){
      this.$refs['partyForm'].resetFields()
    },
    async blurCheck(val){
      let holderName;
      let currentIdx;
      val.forEach((item,idx) => {
        if(item.isConflict!==1||item.isConflict!==0){
          if(item.nameinput){
            holderName = item.nameinput;
            currentIdx = idx
          }
        }
      });
      if(holderName){
        let res = await conflictOfInterestCheck({
          holderName,
          sideType:this.title==='我方当事人'?1:2
        });
        if(res&&res.code==200){
          // false有冲突 0
          // true无冲突 1
          res.data===true?this.ruleForm.formList[currentIdx].isConflict = 1:this.ruleForm.formList[currentIdx].isConflict = 0;
        }
      }
      
    },
    async querySearchAsync(queryString, cb){
      if(queryString){
        let res = await customerNameSelect({
          customerName:queryString
        });
        if(res&&res.code==200){
          this.serchData = res.data;
          cb(res.data);
        }       
      }
    },
    add(){
      this.ruleForm.formList.push({
        id:Date.now(),
        type:'',
        identify:'',
        nameinput:'',
        isCreatedCustom:false,
        isConflict:''
      })
    },
    deletItem(idx){
      this.ruleForm.formList.splice(idx,1)
    },
    conflict(){
      let routeData = this.$router.resolve({name: 'Conflict'});
      window.open(routeData.href, '_blank');
    }
  }
})
</script>
<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.party{
  padding:30px;
  // height: 192px;
  .title{
    display: flex;
    align-items: center;
    margin-bottom:20px;
    .text{
      font-size: 14px;
      color:#666;
    }
    .el-icon-circle-plus-outline{
      font-size: 24px;
      color:#999;
      margin-left:20px;
      &:hover{
        cursor: pointer;
        color:$main-color;
      }
    }
  }
  .partyCon{
    padding:10px 20px 0 20px;
    background: #FAFAFA;
    margin-top:20px;
    border-radius: 10px;
    .partyUl{
      .partyLi{
        .itemTop{
          display: flex;
          align-items: center;
          .typeSelect{
            &:first-child{
              margin-right: 4px;
              // ::v-deep{
              //   .el-input__inner{
              //     border-right: none;
              //     border-top-right-radius: 0;
              //     border-bottom-right-radius: 0;
              //   }
              // }
            }
            // &:nth-child(2){
            //   ::v-deep{
            //     .el-input__inner{
            //       border-top-left-radius: 0;
            //       border-bottom-left-radius: 0;
            //     }
            //   }
            // }
          }
          .el-icon-delete{
            margin-left:20px;
            font-size: 20px;
            color:#999;
            &:hover{
              cursor: pointer;
              color:$main-color;
            }
          }
        }
        .itemName{
          margin:20px 0 10px 0;
          width:464px;
        }
        .itembottom{
          width:464px;
          font-size: 12px;
          color:#666;
          display: flex;
          align-items: center;
          justify-content: space-between;
          ::v-deep{
            .el-checkbox__label{
              font-size: 12px;
              color:#666;
            }
          }
          .status{
            display: flex;
            align-items: center;
            font-size: 12px;
            color:#666;
            .icon{
              font-size:18px;
              margin-right: 10px;
            }
          }
          .none{
            color:#62BE8A
          }
          .exist{
            color:#E8431C;
            &:hover{
              cursor: pointer;
            }
          }
          
        }
        .fleend{
          display: flex;
          align-items: center;
          justify-content: flex-end
        }
      }
    }
  }
}
</style>
