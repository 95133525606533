<template>
  <div class="CaseMainDetailsBox">
    <div class="CaseMainDetails">
      <div class="leftBox">
      <!-- 基本信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">基本信息</div>
          <div class="iconBox el-icon-edit-outline" 
          @click='openOppIsShow("base")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">案件编号</div>
            <div class="valueTitle">{{form.caseNo}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">委托时间</div>
            <div class="valueTitle">{{form.entrustDate}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">案件发生时间</div>
            <div class="valueTitle">{{form.happenDate}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">案件标的</div>
            <div class="valueTitle">{{form.subject}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">标的金额</div>
            <div class="valueTitle">{{form.subjectAmount}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">事实与理由</div>
            <div class="valueTitle">{{form.factualReason}}</div>
          </div>
        </div>
      </div>
      <!-- 经办律师 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">经办律师</div>
          <div class="iconBox el-icon-edit-outline" 
          @click='openOppIsShow("lawyer")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem"
          v-for='item in form.relLawyerList' :key='item.selfKey'>
            <div class="keyIcon iconfont icontouxiang"></div>
            <div class="keyTitle" style="textAlign:left">{{item.lawyerName}}</div>
            <div class="valueTitle">{{item.lawyerTypeName}}</div>
            <!-- <div class="valueTitle">{{item.relRemark}}</div> -->
          </div>
        </div>
      </div>
      <!-- 收费信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">收费信息</div>
          <div class="iconBox el-icon-edit-outline" 
          @click='openOppIsShow("charge")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">收费方式</div>
            <div class="valueTitle">{{form.feeTypeName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">合同预估</div>
            <div class="valueTitle">{{form.estimatedAmount}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle textRight">备注</div>
            <div class="valueTitle flex1">{{form.feeRemark}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightBox">
      <!-- 当事人信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">当事人信息</div>
          <div class="iconBox el-icon-edit-outline" 
          @click='openOppIsShow("dsr")'></div>
        </div>
        <div class="contentBox">
          <div class="tableBoxCon">
            <div class="tableTitle">我方</div>
            <div class="tableBox">
              <div class="tableItem" 
              v-for='item in form.ourLitigantList'
              :key='item.selfKey'>
                <div class="tableItemName">{{item.holderName}}</div>
                <div class="tableItemName">{{item.holderTypeName}}</div>
                <div class="tableItemName">{{item.partyTypeName}}</div>
              </div>
            </div>
          </div>
          <div class="tableBoxCon">
            <div class="tableTitle">对方</div>
            <div class="tableBox">
              <div class="tableItem" 
              v-for='item in form.oppositeLitigantList'
              :key='item.selfKey'>
                <div class="tableItemName">{{item.holderName}}</div>
                <div class="tableItemName">{{item.holderTypeName}}</div>
                <div class="tableItemName">{{item.partyTypeName}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 法院信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">法院信息</div>
          <div class="iconBox el-icon-edit-outline" 
          @click='openOppIsShow("fy")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">法院名称</div>
            <div class="valueTitle">{{form.courtName}}</div>
          </div>
          <div class="contentItem">
            <div class="keyTitle">庭审级别</div>
            <div class="valueTitle">{{form.courtLevelName}}</div>
          </div>
          <div class="contentItem" v-if='form.courtCaseNo'>
            <div class="keyTitle">法院案号</div>
            <div class="valueTitle">{{form.courtCaseNo}}</div>
          </div>
          <div class="contentItem" v-if='form.presidingJudgeName'>
            <div class="keyTitle">主审法官</div>
            <div class="valueTitle">{{form.presidingJudgeName}}</div>
          </div>
          <div class="contentItem" v-if='form.presidingJudgeTel'>
            <div class="keyTitle">联系方式</div>
            <div class="valueTitle">{{form.presidingJudgeTel}}</div>
          </div>
          <div class="contentItem" v-if='form.clerkName'>
            <div class="keyTitle">书记员</div>
            <div class="valueTitle">{{form.clerkName}}</div>
          </div>
          <div class="contentItem" v-if='form.clerkTel'>
            <div class="keyTitle">联系方式</div>
            <div class="valueTitle">{{form.clerkTel}}</div>
          </div>
          <div class="contentItem" v-if='form.courtProvince||form.courtAddr'>
            <div class="keyTitle">法院地址</div>
            <div class="valueTitle">{{form.courtProvince}}{{form.courtCity}}{{form.courtTown}}{{form.courtAddr}}</div>
          </div>
        </div>
      </div>
      <!-- 公安信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">公安信息</div>
          <div class="iconBox el-icon-edit-outline" 
          @click='openOppIsShow("gn")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">公安局</div>
            <div class="valueTitle">{{form.policeOfficeName}}</div>
          </div>
          <div class="contentItem" v-if='form.policeOfficeContacter'>
            <div class="keyTitle">联系人</div>
            <div class="valueTitle">{{form.policeOfficeContacter}}</div>
          </div>
           <div class="contentItem" v-if='form.policeOfficeTel'>
            <div class="keyTitle">联系电话</div>
            <div class="valueTitle">{{form.policeOfficeTel}}</div>
          </div>
          <div class="contentItem" v-if='form.policeOfficeProvince||form.policeOfficeAddr'>
            <div class="keyTitle">地址</div>
            <div class="valueTitle">{{form.policeOfficeProvince}}{{form.policeOfficeCity}}{{form.policeOfficeTown}}{{form.policeOfficeAddr}}</div>
          </div>
        </div>
      </div>
      <!-- 看守所信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">看守所信息</div>
          <div class="iconBox el-icon-edit-outline" 
          @click='openOppIsShow("kss")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">看守所</div>
            <div class="valueTitle">{{form.jailName}}</div>
          </div>
          <div class="contentItem" v-if='form.jailContacter'>
            <div class="keyTitle">联系人</div>
            <div class="valueTitle">{{form.jailContacter}}</div>
          </div>
          <div class="contentItem" v-if='form.jailContactTel'>
            <div class="keyTitle">联系电话</div>
            <div class="valueTitle">{{form.jailContactTel}}</div>
          </div>
          <div class="contentItem" v-if='form.jailProvince||form.jailAddr'>
            <div class="keyTitle">地址</div>
            <div class="valueTitle">{{form.jailProvince}}{{form.jailCity}}{{form.jailTown}}{{form.jailAddr}}</div>
          </div>
        </div>
      </div>
      <!-- 检察院信息 -->
      <div class="itemBox">
        <div class="titleBox">
          <div class="title">检察院信息</div>
          <div class="iconBox el-icon-edit-outline" 
          @click='openOppIsShow("jcy")'></div>
        </div>
        <div class="contentBox">
          <div class="contentItem">
            <div class="keyTitle">检察院</div>
            <div class="valueTitle">{{form.procuratorateName}}</div>
          </div>
          <div class="contentItem" v-if='form.procuratorateContacter'>
            <div class="keyTitle">联系人</div>
            <div class="valueTitle">{{form.procuratorateContacter}}</div>
          </div>
          <div class="contentItem" v-if='form.procuratorateTel'>
            <div class="keyTitle">联系电话</div>
            <div class="valueTitle">{{form.procuratorateTel}}</div>
          </div>
          <div class="contentItem" v-if='form.procuratorateProvince||form.procuratorateAddr'>
            <div class="keyTitle">地址</div>
            <div class="valueTitle">{{form.procuratorateProvince}}{{form.procuratorateCity}}{{form.procuratorateTown}}{{form.procuratorateAddr}}</div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <!-- 基本信息 -->
    <myDialog
    parentRef='base'
    
    :title='oppTitle'
    :isShow='baseOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo">
        <div class="formBox">
          <el-form ref="formBase" 
          :model="form" 
          label-width="120px">
            <el-form-item label="案件名称"
            prop='caseName'
            :rules="{
              required: true, message: '案件名称不能为空', trigger: 'blur'
            }">
              <el-input size='small' style='width:350px'
              v-model="form.caseName" clearable></el-input>
            </el-form-item>
            <el-form-item label="类型"
            prop='caseType'
            :rules="{
              required: true, message: '案件类型不能为空', trigger: ['blur','change']
            }">
              <el-select size='small' style='width:350px'
              v-model="form.caseType" placeholder="请选择">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="案件编号" prop="caseNo">
              <el-input style='width:350px' size='small' v-model="form.caseNo" clearable></el-input>
            </el-form-item>
            <el-form-item label="委托时间" prop='entrustDate'>
              <el-date-picker size='small' style='width:350px'
                v-model="form.entrustDate"
                type="datetime"
                placeholder="选择委托时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="案件发生时间" prop='happenDate'>
              <el-date-picker size='small' style='width:350px'
                v-model="form.happenDate"
                type="datetime"
                placeholder="选择案件发生时间">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="标的" prop='subject'>
              <el-input size='small' style='width:350px'
              v-model="form.subject" clearable></el-input>
            </el-form-item>
            <el-form-item label="标的金额" prop='subjectAmount'>
              <el-input size='small' style='width:350px'
              v-model="form.subjectAmount" clearable></el-input>
            </el-form-item>
            <el-form-item label="事实与理由" prop='factualReason'>
              <el-input size='small' type='textarea' style='width:350px'
              v-model="form.factualReason" clearable></el-input>
            </el-form-item>
          </el-form>
          <div class="btnFooter">
            <el-button size='small' type="primary" plain 
            @click='closeDialog("base")'>取消</el-button>
            <el-button size='small' type="primary" 
            style='margin-left:20px'
            @click='saveCaseInfo("base")'
            >保存</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 收费信息 -->
    <myDialog
    parentRef='charge'
   
    title='编辑收费信息'
    :isShow='chargeOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo">
        <div class="formBox">
          <el-form 
          :model="form" 
          :rules="chargRules" 
          ref="chargformBox" 
          label-width="110px" >
          <el-form-item label="收费方式" 
          prop="feeType">
            <el-select class='width450'
            size='small'
            v-model="form.feeType" 
            placeholder="请选择收费方式">
              <el-option
                v-for="item in chargOptions"
                :key="item.key"
                :label="item.value"
                :value="item.key">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="合同预估金额" prop="estimatedAmount">
            <el-input size='small' placeholder="请输入合同预估金额" 
            v-model="form.estimatedAmount">
              <template #append>元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="预计收款时间" prop="feeReceiptDate">
            <el-date-picker style='width:450px' size='small'
                  v-model="form.feeReceiptDate"
                  type="datetime"
                  placeholder="选择日期时间">
                </el-date-picker>
          </el-form-item>
            <el-form-item label="备注" prop="feeRemark">
              <div class="textareaBox">
                <el-input size='small' type='textarea' 
                v-model="form.feeRemark" clearable></el-input>
              </div>
            </el-form-item>
            <div class="btnFooter" style='width:450px;'>
              <el-button size='small' type="primary" plain
              @click='closeDialog("charge")'>取消</el-button>
              <el-button size='small' type="primary" style='margin-left:20px'
              @click='saveCaseInfo("charge")'>保存</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </myDialog>
    <!-- 经办律师 -->
    <myDialog
    parentRef='lawyer'
    title='编辑经办律师'
    :isShow='lawyerOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo">
        <div class="formBox">
          <div class='lawyerFormBox'>
            <lawyerParty ref='lawyerform' :lawyerArr='form.relLawyerList'></lawyerParty>
          </div>
          <div class="textareaBox">
            <div class='text'>备注</div>
            <el-input size='small' type='textarea' 
              v-model="form.relRemark" clearable></el-input>
          </div>
          <div class="btnFooter" style='width:450px;'>
            <el-button size='small' type="primary" plain
            @click='closeDialog("lawyer")'>取消</el-button>
            <el-button size='small' type="primary" style='margin-left:20px'
            @click='saveCaseInfo("lawyer")'>保存</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 当事人信息 -->
    <myDialog
    parentRef='dsr'
    
    title='编辑当事人信息'
    :isShow='dsrOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo" style='padding-top:0'>
        <div class="formBox">
          <div class='partyFormBox'>
            <div>
              <party ref='dsrParty' :listArr='form.ourLitigantList'></party>
            </div>
            <div class="ppartBoX">
              <party title='对方当事人' ref='dsrParty2' :listArr='form.oppositeLitigantList'></party>
            </div>
          </div>
          <div class="btnFooter" style='width:450px;'>
            <el-button size='small' type="primary" plain
            @click='closeDialog("dsr")'>取消</el-button>
            <el-button size='small' type="primary" style='margin-left:20px'
            @click='saveCaseInfo("dsr")'>保存</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 法院信息 -->
    <myDialog
    parentRef='fy'
    
    title='编辑法院信息'
    :isShow='fyOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo fyFormBox">
        <div class="formBox">
          <el-form ref="fyform" 
          :model="form" 
          label-width="100px">
            <el-form-item label="法院名称"
            prop='courtName'
            >
              <el-input size='small' style='width:350px'
              v-model="form.courtName" clearable placeholder="请输入法院名称"></el-input>
            </el-form-item>
            <el-form-item label=" 庭审级别"
            prop='courtLevel'
            >
              <el-select size='small' style='width:350px'
              v-model="form.courtLevel">
                <el-option
                  v-for="item in courtOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key">
                </el-option>
              </el-select>
            </el-form-item>
            <div class="itemInline">
              <el-form-item label="主审法官"
              prop='presidingJudgeName'>
                <el-input style='width:100px' size='small' 
                v-model="form.presidingJudgeName" clearable placeholder="请输入主审法官"></el-input>
              </el-form-item>
              <el-form-item label="联系方式"
              prop='presidingJudgeTel'>
                <el-input style='width:150px' size='small' 
                v-model="form.presidingJudgeTel" clearable placeholder="请输入主审法官联系方式"></el-input>
              </el-form-item>
            </div>
            <div class="itemInline">
              <el-form-item label="书记员" prop='clerkName'>
                <el-input style='width:100px' size='small' 
                v-model="form.clerkName" clearable placeholder="请输入书记员"></el-input>
              </el-form-item>
              <el-form-item label="联系方式" prop='clerkTel'>
                <el-input style='width:150px' size='small' 
                v-model="form.clerkTel" clearable placeholder="请输入书记员联系方式"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="法院案号" prop='courtCaseNo'>
              <el-input size='small' style='width:350px' placeholder="请输入法院案号"
              v-model="form.courtCaseNo" clearable></el-input>
            </el-form-item>
            <el-form-item label="法院地址" prop='courtArea'>
              <div class="columItem">
                <el-cascader size='small' style='width:350px'
                  v-model="form.courtArea"
                  :options="courtAddressOptions"
                >
                </el-cascader>
              </div>
            </el-form-item>
            <el-form-item prop='courtAddr'>
              <div class="columItem">
                <el-input size='small' style='width:350px' type='textarea'
              v-model="form.courtAddr" clearable placeholder="请输入详细地址"></el-input>
              </div>
            </el-form-item>
          </el-form>
          <div class="btnFooter">
            <el-button size='small' type="primary" plain
            @click='closeDialog("fy")'>取消</el-button>
            <el-button size='small' type="primary" style='margin-left:20px'
            @click='saveCaseInfo("fy")'>保存</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 公安信息 -->
    <myDialog
    parentRef='gn'
    
    title='编辑公安信息'
    :isShow='gnOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo fyFormBox">
        <div class="formBox">
          <el-form ref="gnform" 
          :model="form" 
          label-width="100px">
            <el-form-item label="派出所名称"
            prop='policeOfficeName'
            >
              <el-input size='small' style='width:350px'
              v-model="form.policeOfficeName" clearable placeholder="请输入派出所名称"></el-input>
            </el-form-item>
            <div class="itemInline">
              <el-form-item label="联系人" prop='policeOfficeContacter'>
                <el-input style='width:100px' size='small' 
                v-model="form.policeOfficeContacter" clearable placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="联系方式" prop='policeOfficeTel'>
                <el-input style='width:150px' size='small' 
                v-model="form.policeOfficeTel" clearable placeholder="请输入联系人联系方式"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="地址" prop='gnAddress'>
              <div class="columItem">
                <el-cascader size='small' style='width:350px'
                  v-model="form.gnAddress"
                  :options="courtAddressOptions"
                  >
                </el-cascader>
              </div>
            </el-form-item>
            <el-form-item prop='policeOfficeAddr'>
              <div class="columItem">
                <el-input size='small' style='width:350px' type='textarea'
              v-model="form.policeOfficeAddr" clearable placeholder="请输入详细地址"></el-input>
              </div>
            </el-form-item>
          </el-form>
          <div class="btnFooter">
            <el-button size='small' type="primary" plain
            @click='closeDialog("gn")'>取消</el-button>
            <el-button size='small' type="primary" style='margin-left:20px'
            @click='saveCaseInfo("gn")'>保存</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 看守所信息 -->
    <myDialog
    parentRef='kss'
    
    title='编辑看守所信息'
    :isShow='kssOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo fyFormBox">
        <div class="formBox">
          <el-form ref="kssform" 
          :model="form" 
          label-width="100px">
            <el-form-item label="看守所名称"
            prop='jailName'
            >
              <el-input size='small' style='width:350px'
              v-model="form.jailName" clearable placeholder="请输入看守所名称"></el-input>
            </el-form-item>
            <div class="itemInline">
              <el-form-item label="联系人" prop='jailContacter'>
                <el-input style='width:100px' size='small' 
                v-model="form.jailContacter" clearable placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="联系方式" prop='jailContactTel'>
                <el-input style='width:150px' size='small' 
                v-model="form.jailContactTel" clearable placeholder="请输入联系人联系方式"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="地址" prop='kssAddress'>
              <div class="columItem">
                <el-cascader size='small' style='width:350px'
                  v-model="form.kssAddress"
                  :options="courtAddressOptions"
                  >
                </el-cascader>
              </div>
            </el-form-item>
            <el-form-item prop='jailAddr'>
              <div class="columItem">
                <el-input size='small' style='width:350px' type='textarea'
              v-model="form.jailAddr" clearable placeholder="请输入详细地址"></el-input>
              </div>
            </el-form-item>
          </el-form>
          <div class="btnFooter">
            <el-button size='small' type="primary" plain
            @click='closeDialog("kss")'>取消</el-button>
            <el-button size='small' type="primary" style='margin-left:20px'
            @click='saveCaseInfo("kss")'>保存</el-button>
          </div>
        </div>
      </div>
    </myDialog>
    <!-- 检察院信息 -->
    <myDialog
    parentRef='jcy'
    
    title='编辑检察院信息'
    :isShow='jcyOppIsShow'
    :closeDialog='closeDialog'>
      <div class="baseInfo fyFormBox">
        <div class="formBox">
          <el-form ref="jcyform" 
          :model="form" 
          label-width="100px">
            <el-form-item label="检察院名称"
            prop='procuratorateName'
            >
              <el-input size='small' style='width:350px'
              v-model="form.procuratorateName" clearable placeholder="请输入检察院名称"></el-input>
            </el-form-item>
            <div class="itemInline">
              <el-form-item label="联系人" prop='procuratorateContacter'>
                <el-input style='width:100px' size='small' 
                v-model="form.procuratorateContacter" clearable placeholder="请输入联系人"></el-input>
              </el-form-item>
              <el-form-item label="联系方式" 
              prop='procuratorateTel'>
                <el-input style='width:150px' size='small' 
                v-model="form.procuratorateTel" clearable placeholder="请输入联系人联系方式"></el-input>
              </el-form-item>
            </div>
            <el-form-item label="地址" prop='jcyAddress'>
              <div class="columItem">
                <el-cascader size='small' style='width:350px'
                  v-model="form.jcyAddress"
                  :options="courtAddressOptions"
                  >
                </el-cascader>
              </div>
            </el-form-item>
             <el-form-item prop='procuratorateAddr'>
              <div class="columItem">
                <el-input size='small' style='width:350px' type='textarea'
              v-model="form.procuratorateAddr" clearable placeholder="请输入详细地址"></el-input>
              </div>
            </el-form-item>
          </el-form>
          <div class="btnFooter">
            <el-button size='small' type="primary" plain
            @click='closeDialog("jcy")'>取消</el-button>
            <el-button size='small' type="primary" style='margin-left:20px'
            @click='saveCaseInfo("jcy")'>保存</el-button>
          </div>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import myDialog from '@/components/myDialog.vue';
import lawyerParty from '@/components/lawyerParty.vue';
import party from '@/components/party.vue';
import {regionData} from 'element-china-area-data';
import {saveCaseBasicInfo,saveCaseOtherInfo} from '@/api';
import {ElMessage} from 'element-plus';
import moment from 'moment';
export default defineComponent({
  name:"CaseMainDetails",
  components:{
    myDialog,
    lawyerParty,
    party
  },
  props:{
    caseInfo:{
      type:Object,
      default:function(){
        return {}
      }
    }
  },
  async mounted(){
    this.typeOptions = await this.$store.dispatch('getDataDictionary',10003);
    this.chargOptions = await this.$store.dispatch('getDataDictionary',10010);
    this.courtOptions = await this.$store.dispatch('getDataDictionary',10011);
    // 相关律师
    // this.relLawyerList()
    // 相关联系人

  },
  watch:{
    caseInfo(newV,oldV){
      this.mapFormToData(newV);
    }
  },
  data(){
    return {
      oppTitle:'编辑基本信息',
      baseOppIsShow:false,
      lawyerOppIsShow:false,
      chargeOppIsShow:false,
      dsrOppIsShow:false,
      fyOppIsShow:false,
      gnOppIsShow:false,
      kssOppIsShow:false,
      jcyOppIsShow:false,
      // 基本信息
      form: {
        // 自定义法院省市区
        courtArea:'',

      },
      typeOptions:[],
      // 经办律师
      beizhu:'',
      chargRules:{
        chargingMethod:[
          { required: true, message: '请选择收费方式', trigger: 'change' }
        ]
      },
      chargFormData:{
        chargeType:1,
        account:'',
        beizhu:'',
      },
      chargOptions:[],
      courtOptions:[],
      courtAddress:'',
      courtAddressOptions:regionData,
    }
  },
  methods:{
    // // 相关律师
    // async relLawyerList(){
    //   let res = await getRelLawyerList({
    //     relId:this.$route.params.caseId,
    //     relType:2
    //   });
    //   console.log('相关律师:',res)
    // },
    // // 相关联系人

    async getDictionaryName(props){
      return await this.$store.dispatch('getDictionaryName',props);
    },
    async mapFormToData(newV){
      // 经办律师
      this.form.relLawyerList = newV.relLawyerList.map((item,idx)=>{
        item.selfKey = Date.now() + idx + item.lawyerType + item.lawyerName;
        return item
      })
      // 对方当事人
      this.form.oppositeLitigantList = newV.oppositeLitigantList.map((item,idx)=>{
        item.selfKey = Date.now() + idx + item.holderName + item.holderType + item.holderType;
        return item
      });
      // 我方当事人
      this.form.ourLitigantList = newV.ourLitigantList.map((item,idx)=>{
        item.selfKey = Date.now() + idx + item.holderName + item.holderType + item.holderType;
        return item
      });
      // 律师备注
      this.form.relRemark = newV.relRemark;
      // 案件名称
      this.form.caseName = newV.caseName;
      // 案号
      this.form.caseNo = newV.caseNo;
      // 状态
      this.form.caseStatus = newV.caseStatus;
      // 类型
      this.form.caseType = newV.caseType;
      // 书记员名称
      this.form.clerkName = newV.clerkName;
      // 书记员电话
      this.form.clerkTel = newV.clerkTel;
      // 法院地址
      this.form.courtAddr = newV.courtAddr;
      // 法院案号
      this.form.courtCaseNo = newV.courtCaseNo;
      // 法院城市
      this.form.courtCity = newV.courtCity;
      // 庭审级别
      this.form.courtLevel = newV.courtLevel;
      // 自定义庭审级别
      this.form.courtLevelName = await this.getDictionaryName({id:10011,status:newV.courtLevel});
      // 法院名称
      this.form.courtName = newV.courtName;
      // 法院所属省份
      this.form.courtProvince = newV.courtProvince;
      // 法院所属区
      this.form.courtTown = newV.courtTown;
      // 案源客户id
      this.form.customerId = newV.customerId;
      // 委托日期
      this.form.entrustDate = newV.entrustDate;
      // 预估金额
      this.form.estimatedAmount = newV.estimatedAmount;
      // 事实理由
      this.form.factualReason = newV.factualReason;
      // 费用备注
      this.form.feeRemark = newV.feeRemark;
      // 收费方式
      this.form.feeType = newV.feeType;
      // 自定义收费方式
      this.form.feeTypeName = await this.getDictionaryName({id:10010,status:newV.feeType});
      // 案件发生日期
      this.form.happenDate = newV.happenDate;
      // 看守所详细地址
      this.form.jailAddr = newV.jailAddr;
      // 看守所所属城市
      this.form.jailCity = newV.jailCity;
      // 看守所联系人电话
      this.form.jailContactTel = newV.jailContactTel;
      // 看守所联系人
      this.form.jailContacter = newV.jailContacter;
      // 看守所名称
      this.form.jailName = newV.jailName;
      // 看守所所属省份
      this.form.jailProvince = newV.jailProvince;
      // 看守所所属区
      this.form.jailTown = newV.jailTown;
      // 律师团队备注
      this.form.lawyerTeamRemark = newV.lawyerTeamRemark;
      // 派出所详细地址
      this.form.policeOfficeAddr = newV.policeOfficeAddr;
      // 派出所名称
      this.form.policeOfficeName = newV.policeOfficeName;
      // 派出所所属城市
      this.form.policeOfficeCity = newV.policeOfficeCity;
      // 派出所联系人
      this.form.policeOfficeContacter = newV.policeOfficeContacter;
      // 派出所所属省份
      this.form.policeOfficeProvince = newV.policeOfficeProvince;
      // 派出所联系人电话
      this.form.policeOfficeTel = newV.policeOfficeTel;
      // 派出所所属区
      this.form.policeOfficeTown = newV.policeOfficeTown;
      // 主审法官名称
      this.form.presidingJudgeName = newV.presidingJudgeName;
      // 主审法官电话
      this.form.presidingJudgeTel = newV.presidingJudgeTel;
      // 检察院详细地址
      this.form.procuratorateAddr = newV.procuratorateAddr;
      // 检察院所属城市
      this.form.procuratorateCity = newV.procuratorateCity;
      // 检察院联系人
      this.form.procuratorateContacter = newV.procuratorateContacter;
      // 检察院名称
      this.form.procuratorateName = newV.procuratorateName;
      // 检察院所属省份
      this.form.procuratorateProvince = newV.procuratorateProvince;
      // 检察院联系人电话
      this.form.procuratorateTel = newV.procuratorateTel;
      // 检察院所属区
      this.form.procuratorateTown = newV.procuratorateTown;
      // 费用预计收款日期
      this.form.feeReceiptDate = newV.feeReceiptDate;
      // 争议标的
      this.form.subject = newV.subject;
      // 标的金额
      this.form.subjectAmount = newV.subjectAmount;
    },

    openOppIsShow(typeOpp){
      switch(typeOpp){
        case 'base':
          this.baseOppIsShow = true;
          break;
        case 'lawyer':
          this.lawyerOppIsShow = true;
          break;
        case 'charge':this.chargeOppIsShow = true;break;
        case 'dsr':this.dsrOppIsShow = true;break;
        case 'fy':
          this.fyOppIsShow = true;
          // 自定义法院省市区
          this.form.courtArea = this.areaTransform2([this.form.courtProvince,this.form.courtCity,this.form.courtTown]);
          break;
        case 'gn':
          this.gnOppIsShow = true;
          // 自定义省市区
          this.form.gnAddress = this.areaTransform2([this.form.policeOfficeProvince,this.form.policeOfficeCity,this.form.policeOfficeTown]);
          break;
        case 'kss':
          this.kssOppIsShow = true;
          // 自定义省市区
          this.form.kssAddress = this.areaTransform2([this.form.jailProvince,this.form.jailCity,this.form.jailTown]);
          break;
        case 'jcy':
          this.jcyOppIsShow = true;
          // 自定义省市区
          this.form.jcyAddress = this.areaTransform2([this.form.procuratorateProvince,this.form.procuratorateCity,this.form.procuratorateTown]);
          ;break;
      }
    },
    afterLeave(typeOpp){
      // console.log('afterLeave:',typeOpp);
      // switch(typeOpp){
      //   case 'base':this.baseOppIsShow = false;break;
      //   case 'lawyer':this.lawyerOppIsShow = false;break;
      //   case 'charge':this.chargeOppIsShow = false;break;
      //   case 'dsr':this.dsrOppIsShow = false;break;
      //   case 'fy':this.fyOppIsShow = false;break;
      //   case 'gn':this.gnOppIsShow = false;break;
      //   case 'kss':this.kssOppIsShow = false;break;
      //   case 'jcy':this.jcyOppIsShow = false;break;
      // }
    },
    closeDialog(v){
      if(v){
        switch(v){
          case 'base':
            this.baseOppIsShow = false;
            this.$refs['formBase'].resetFields()
            break;
          case 'charge':
            this.chargeOppIsShow = false;
            this.$refs['chargformBox'].resetFields()
            break;
          case 'fy':
            this.fyOppIsShow = false;
            this.$refs['fyform'].resetFields()
            break;
          case 'lawyer':
            this.lawyerOppIsShow = false;
            break;
          case 'dsr':
            this.dsrOppIsShow = false;
            break;
          case 'gn':
            this.gnOppIsShow = false;
            this.$refs['gnform'].resetFields()
            break;
          case 'kss':
            this.kssOppIsShow = false;
            this.$refs['kssform'].resetFields()
            break;
          case 'jcy':
            this.jcyOppIsShow = false;
            this.$refs['jcyform'].resetFields()
            break;
        }
      }
    },
    async saveCaseInfo(v){
      switch(v){
        case 'base':
          if(this.form.entrustDate){
            this.form.entrustDate = moment(this.form.entrustDate).format('YYYY-MM-DD HH:mm:ss')
          };
          if(this.form.happenDate){
            this.form.happenDate = moment(this.form.happenDate).format('YYYY-MM-DD HH:mm:ss')
          };
          this.changeSaveCaseInfo({
            caseId:this.$route.params.caseId,
            caseName:this.form.caseName,
            caseNo:this.form.caseNo,
            caseType:this.form.caseType,
            entrustDate:this.form.entrustDate,
            happenDate:this.form.happenDate,
            subject:this.form.subject,
            subjectAmount:this.form.subjectAmount,
            factualReason:this.form.factualReason
          },'base')
          break;
        case 'charge':
          if(this.form.feeReceiptDate){
            this.form.feeReceiptDate = moment(this.form.feeReceiptDate).format('YYYY-MM-DD HH:mm:ss')
          };
          this.saveCaseOtherInfo({
            caseId:this.$route.params.caseId,
            feeType:this.form.feeType,
            estimatedAmount:this.form.estimatedAmount,
            feeReceiptDate:this.form.feeReceiptDate,
            feeRemark:this.form.feeRemark,
          },'charge')
          break;
        case 'fy':
          let area = this.areaTransform(this.form.courtArea);
          if(area.length){
            if(area[2]){
              this.form.courtTown = area[2];
            }else{
              this.form.courtTown = '';
            };
            this.form.courtProvince = area[0];
            this.form.courtCity = area[1];
          }
          this.saveCaseOtherInfo({
            caseId:this.$route.params.caseId,
            courtCaseNo:this.form.courtCaseNo,
            courtName:this.form.courtName,
            courtLevel:this.form.courtLevel,
            presidingJudgeName:this.form.presidingJudgeName,
            presidingJudgeTel:this.form.presidingJudgeTel,
            clerkName:this.form.clerkName,
            clerkTel:this.form.clerkTel,
            courtAddr:this.form.courtAddr,
            courtProvince:this.form.courtProvince,
            courtCity:this.form.courtCity,
            courtTown:this.form.courtTown,
          },'fy')
          break;
        case 'gn':
          let gnarea = this.areaTransform(this.form.gnAddress);
          if(gnarea.length){
            if(gnarea[2]){
              this.form.policeOfficeTown = gnarea[2];
            }else{
              this.form.policeOfficeTown = '';
            };
            this.form.policeOfficeProvince = gnarea[0];
            this.form.policeOfficeCity = gnarea[1];
          }
          this.saveCaseOtherInfo({
            caseId:this.$route.params.caseId,
            policeOfficeName:this.form.policeOfficeName,
            policeOfficeContacter:this.form.policeOfficeContacter,
            policeOfficeTel:this.form.policeOfficeTel,
            policeOfficeAddr:this.form.policeOfficeAddr,
            policeOfficeProvince:this.form.policeOfficeProvince,
            policeOfficeCity:this.form.policeOfficeCity,
            policeOfficeTown:this.form.policeOfficeTown,
          },'gn');
          break;
        case 'kss':
          let kssarea = this.areaTransform(this.form.kssAddress);
          if(kssarea.length){
            if(kssarea[2]){
              this.form.jailTown = kssarea[2];
            }else{
              this.form.jailTown = '';
            };
            this.form.jailProvince = kssarea[0];
            this.form.jailCity = kssarea[1];
          }
          this.saveCaseOtherInfo({
            caseId:this.$route.params.caseId,
            jailName:this.form.jailName,
            jailContacter:this.form.jailContacter,
            jailContactTel:this.form.jailContactTel,
            jailAddr:this.form.jailAddr,
            jailProvince:this.form.jailProvince,
            jailCity:this.form.jailCity,
            jailTown:this.form.jailTown,
          },'kss');
          break;
        case 'jcy':
          let jcyarea = this.areaTransform(this.form.jcyAddress);
          if(jcyarea.length){
            if(jcyarea[2]){
              this.form.procuratorateTown = jcyarea[2];
            }else{
              this.form.procuratorateTown = '';
            };
            this.form.procuratorateProvince = jcyarea[0];
            this.form.procuratorateCity = jcyarea[1];
          }
          this.saveCaseOtherInfo({
            caseId:this.$route.params.caseId,
            procuratorateName:this.form.procuratorateName,
            procuratorateContacter:this.form.procuratorateContacter,
            procuratorateTel:this.form.procuratorateTel,
            procuratorateAddr:this.form.procuratorateAddr,
            procuratorateProvince:this.form.procuratorateProvince,
            procuratorateCity:this.form.procuratorateCity,
            procuratorateTown:this.form.procuratorateTown,
          },'jcy');
          break;
        case 'lawyer':
          let relLawyerList = [];
          this.$refs['lawyerform'].ruleForm.formList.forEach(item=>{
            relLawyerList.push({
              lawyerName:item.nameinput,
              lawyerType:item.type,
              lawyerUserId:item.keyL,
            })
          });
          this.saveCaseOtherInfo({
            caseId:this.$route.params.caseId,
            relLawyerList,
            relRemark:this.form.relRemark
          },'lawyer');
          break;
        case 'dsr':
          // 我方当事人
          let ourLitigantList = [];
          this.$refs['dsrParty'].ruleForm.formList.forEach(item=>{
            ourLitigantList.push({
              createCustomerFlag:item.isCreatedCustom?1:0,
              holderId:'',
              holderName:item.nameinput,
              holderType:item.type,
              partyType:item.identify
            })
          });
          // 对方当事人
          let oppositeLitigantList = [];
          this.$refs['dsrParty2'].ruleForm.formList.forEach(item=>{
            oppositeLitigantList.push({
              createCustomerFlag:item.isCreatedCustom?1:0,
              holderId:'',
              holderName:item.nameinput,
              holderType:item.type,
              partyType:item.identify
            })
          });
          console.log("oppositeLitigantList:",oppositeLitigantList)
          console.log("ourLitigantList:",ourLitigantList)
          return
          this.saveCaseOtherInfo({
            caseId:this.$route.params.caseId,
            oppositeLitigantList,
            ourLitigantList
          },'dsr');
          break;
      };
    },
    // 修改保存案件信息
    async changeSaveCaseInfo(params,oppName){
      let res = await saveCaseBasicInfo(params);
      if(res && res.code==200){
        this.$emit('upLoadCaseInfo')
        ElMessage.success({
          message: '修改成功',
          type: 'success'
        });
        this.closeDialog(oppName);
      }
    },
    async saveCaseOtherInfo(params,oppName){
      let res = await saveCaseOtherInfo(params);
      if(res && res.code==200){
        this.$emit('upLoadCaseInfo')
        ElMessage.success({
          message: '修改成功',
          type: 'success'
        });
        this.closeDialog(oppName);
      }
    },
    // 省市区转换:[number]=>[string]
    areaTransform(params){
      if(params.length&&params[0]&&params[1]){
        let province = params[0];
        let city = params[1];
        let town = '';
        if(params[2]){
          town = params[2]
        };
        let provinceArr = regionData.filter(item=>{
          return item.value == province 
        });
        let cityArr = provinceArr[0].children.filter(item=>{
          return item.value == city 
        });
        province = provinceArr[0].label;
        city = cityArr[0].label;
        if(town){
          let townArr = cityArr[0].children.filter(item=>{
            return item.value == town 
          });
          town = townArr[0].label;
          return [province,city,town]
        }else{
          return [province,city]
        }
      }else{
        return []
      }
    },
    // 省市区转换:[string]=>[number]
    areaTransform2(params){
      if(params.length&&params[0]&&params[1]){
        let province = params[0];
        let city = params[1];
        let town = '';
        if(params[2]){
          town = params[2]
        };
        let provinceArr = regionData.filter(item=>{
          return item.label == province 
        });
        let cityArr = provinceArr[0].children.filter(item=>{
          return item.label == city 
        });
        province = provinceArr[0].value;
        city = cityArr[0].value;
        if(town){
          let townArr = cityArr[0].children.filter(item=>{
            return item.label == town 
          });
          town = townArr[0].value;
          return [province,city,town]
        }else{
          return [province,city]
        }
      }else{
        return []
      }
    },
  }
})
</script>

<style lang="scss" scoped>
@import '@/assets/style/comman.scss';
.CaseMainDetails{
  display: flex;
  width:100%;
  box-sizing: border-box;
  padding:0 30px;
  .leftBox{
    border-right:1px solid #eee;
  }
  .leftBox,.rightBox{
    width:50%;
    box-sizing: border-box;
    .itemBox{
      border-bottom:1px solid #eee;
      padding:30px 0;
      margin:0 20px;
      &:last-child{
        border:none;
      }
      .titleBox{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom:30px;
        .title{
          font-size: 16px;
          color:#666666;
        }
        .iconBox{
          font-size: 26px;
          color:$main-color;
          &:hover{
            cursor: pointer;
          }
        }
      }
      .contentBox{
        padding-left:10px;
        .contentItem{
          margin-bottom:30px;
          display: flex;
          align-items: center;
          .keyTitle{
            min-width:56px;
            width:88px;
            text-align: right;
            margin-right: 20px;
          }
          .textRight{
            text-align: right;
          }
          .flex1{
            flex:1;
            line-height: 20px;
          }
          .keyTitle,.valueTitle{
            font-size: 14px;
            color:#777777;
          }
          .keyIcon{
            font-size: 40px;
            color:$main-color;
            margin-right: 30px;
          }
        }
      }
    }
  }
  .rightBox{
    padding-left:20px;
    .contentBox{
      .tableBoxCon{
        width:90%;
        display: flex;
        margin:auto;
        margin-bottom:20px;
        .tableTitle{
          font-size: 16px;
          color:#666;
          width:60px;
        }
        .tableBox{
          width:100%;
          .tableItem{
            display: flex;
            width:100%;
            margin-bottom:20px;
            &:last-child{
              margin-bottom:0;
            }
            .tableItemName{
              // width:calc( (100% - 60px) / 2 );
              width:165px;
              font-size: 14px;
              color:#777;
              &:nth-child(2){
                width:100px;
              }
              &:nth-child(3){
                width:150px;
              }
            }
          }
        }
      }
    }
  }
}
.baseInfo{
  padding:30px;
  .btnFooter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin:30px auto 0 auto;
  }
  .textareaBox{
    margin-top:30px;
    .text{
      font-size: 14px;
      color:#666;
      margin-bottom:20px;
    }
  }
  .lawyerFormBox{
    display: flex;
    justify-content: center;
    margin-bottom:30px;
  }
  .partyFormBox{
    .ppartBoX{
      border-top:1px solid #eee;
      margin-top:20px;
    }
  }
  .textareaBox{
    width:450px;
    margin:auto
  }
  .el-form-item{
    .el-form-item__content{
      .width450{
        width:450px
      }
    }
  }
}
.fyFormBox{
  .itemInline{
    display: flex;
    align-items: center;
  }
  .columItem{
    margin-bottom:10px
  }
}
</style>